//jsxhook

import React from "react"
import { Box } from "theme-ui"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

export const KlaviyoFormSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const formId = data.primary.klaviyo_form_id

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Box id={data.primary.anchor_id} className={data.primary.text_alignment}>
        <div className={`klaviyo-form-${formId}`}></div>
      </Box>
    </PrismicElementWrapper>
  )
}
