//jsxhook

import { ImageVariants, ProductCardVariant } from "@sixty-six-north/ui-system"
import { usePreferredLanguage } from "i18n/StoreHooks"
import React, { useLayoutEffect, useState, ReactNode } from "react"
import { VariantFilterContext } from "../../category/CategoriesContexts"
import { useAllCategoriesLookup } from "../../category/CategoryHooks"
import ProductCard from "../../product/components/ProductCard"
import {
  Collection,
  ProductRecommendations
} from "../../product/product/Recommendations"
import { hierarchicalProductHasAnyStock } from "../../utils/ProductUtils"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import {
  CarouselStyle,
  PrismicSlice,
  PrismicSliceType,
  PrismicPrimaryProps,
  PrismicCategoryLink
} from "../PrismicModels"
import {
  PrismicRecommendedSlicesContext,
  useRecommendations,
  useCategorisedProducts
} from "../PrismicProductsContext"
import {
  ProjectionWithPreferredColor,
  CollectionOfLooks
} from "../RecommendationFilter"
import { PrismicElementWrapper } from "./Layout"

export const ProductRecommendationProvider: React.FC<{
  slices: PrismicSlice[]
  children?: ReactNode
}> = ({ slices, children }) => {
  const recommendedSlices = slices.filter(
    it => it.slice_type === PrismicSliceType.productRecommendationIds
  )

  return (
    <PrismicRecommendedSlicesContext.Provider value={recommendedSlices}>
      {children}
    </PrismicRecommendedSlicesContext.Provider>
  )
}

const CategorisedProductCard: React.FC<{
  index: number
  product: ProjectionWithPreferredColor
  carouselStyle: CarouselStyle
}> = ({ product, index, carouselStyle }) => {
  const language = usePreferredLanguage()
  const productCategoryPreference = product.productCategory
  const allCategoryLookup = useAllCategoriesLookup()

  const subCategory = allCategoryLookup.firstSubCategoryUnderTopLevel(
    product.product,
    productCategoryPreference || "men"
  )
  const currentCategorySlug = subCategory?.slug?.[language]

  return (
    <ProductCard
      index={index}
      product={product.product}
      color={product.preferredColor}
      badge={product.productBadge}
      categorySlug={currentCategorySlug}
      border={carouselStyle === "original"}
      imageRatio={
        carouselStyle === "carousel" ? ImageVariants.ratio_3x4 : undefined
      }
      productCardVariant={
        carouselStyle === "carousel" ? ProductCardVariant.CAROUSEL : undefined
      }
    />
  )
}

function convertCollectionToCategorisedProductCards(
  collectionsWithProducts: CollectionOfLooks[],
  carouselStyle: CarouselStyle
) {
  return collectionsWithProducts.map(col => ({
    ...col,
    looks: col.looks.map((look, lid) => ({
      ...look,
      products: look.products
        .filter(p => hierarchicalProductHasAnyStock(p.product))
        .map((product, pid) => {
          return () => (
            <CategorisedProductCard
              key={`product-${lid}-${pid}`}
              index={pid}
              product={product}
              carouselStyle={carouselStyle}
            />
          )
        })
    }))
  }))
}

export const ProductRecommendationByIds: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const recommendations = useRecommendations()
  const sectionId = data.primary.product_recommendation_section_id
  const variantAvailability =
    data.primary.product_recommendation_variant_availability
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const collectionsWithProducts =
    recommendations.collectionsForSection(sectionId)

  const carouselStyle: CarouselStyle =
    data.primary.product_recommendation_section_style || "original"

  const collections = convertCollectionToCategorisedProductCards(
    collectionsWithProducts,
    carouselStyle
  )
  return (
    <VariantFilterContext.Provider value={variantAvailability}>
      <ProductCarousel
        title={adapter.productRecommendationTitle("")}
        subtitle={adapter.productRecommendationSubTitle("")}
        collections={collections}
        carouselStyle={carouselStyle}
        prismicStyle={data.primary}
      />
    </VariantFilterContext.Provider>
  )
}

export const CategoryCarousel: React.FC<{
  data: PrismicSlice
}> = ({ data }) => {
  const categorisedProducts = useCategorisedProducts()
  const carouselStyle: CarouselStyle = "carousel"

  const [looksDictionary, setLooksDictionary] = useState<
    Record<string, ProjectionWithPreferredColor[]>
  >({})
  const prismicCategoryTabs = (data?.items || []).filter(notEmpty).map(it => ({
    title: it.tab,
    categoryKey: (it.commerce_tools_category as PrismicCategoryLink)?.key
  }))

  const { dynamic_category_modification: filter, limit } = data?.primary
  useLayoutEffect(() => {
    prismicCategoryTabs.forEach(({ title, categoryKey }) => {
      categorisedProducts
        .fetchProductsForCategory(categoryKey, filter, limit)
        .then(it => {
          setLooksDictionary(prevState => ({
            ...prevState,
            [`${title}`]: it
          }))
        })
    })
  }, [])

  const adapter = new PrismicPrimaryAdapter(data.primary)

  return (
    <ProductCarousel
      title={adapter.productRecommendationTitle("")}
      subtitle={adapter.productRecommendationSubTitle("")}
      collections={convertCollectionToCategorisedProductCards(
        [
          {
            title: "title",
            looks: prismicCategoryTabs.map(it => ({
              title: it.title,
              products: (it.title && looksDictionary[it.title]) || []
            }))
          }
        ],
        carouselStyle
      )}
      carouselStyle={carouselStyle}
      prismicStyle={data.primary}
    />
  )
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

const ProductCarousel: React.FC<{
  title: string
  subtitle: string
  collections: Collection[]
  carouselStyle: CarouselStyle
  prismicStyle: PrismicPrimaryProps
}> = ({ title, subtitle, collections, carouselStyle, prismicStyle }) => {
  return collections.length > 0 ? (
    <PrismicElementWrapper prismicStyle={prismicStyle}>
      <ProductRecommendations
        title={title}
        subTitle={subtitle}
        collections={collections}
        variant={carouselStyle}
        carouselConfiguration={{
          paginationType: prismicStyle.pagination_type || undefined,
          autoPaginateIntervalInSeconds:
            prismicStyle.auto_paginate_interval_in_seconds || undefined,
          animationDurationInSeconds:
            prismicStyle.animation_duration_in_seconds || undefined
        }}
      />
    </PrismicElementWrapper>
  ) : null
}

export const ProductRecommendationByAttribute: React.FC<{
  data: PrismicSlice
}> = ({ data }) => {
  const recommendations = useRecommendations()
  const adapter = new PrismicPrimaryAdapter(data.primary)

  const products = recommendations.productsForAttribute(
    adapter.productRecommendationAttributeKey(""),
    adapter.productRecommendationAttributeValue("")
  )

  const collections: Collection[] = [
    {
      looks: [
        {
          products: products
            .filter(p => hierarchicalProductHasAnyStock(p))
            .map((product, pid) => {
              return () => (
                <ProductCard key={pid} index={pid} product={product} />
              )
            })
        }
      ]
    }
  ]

  return (
    <ProductCarousel
      title={adapter.productRecommendationTitle("")}
      subtitle={adapter.productRecommendationSubTitle("")}
      collections={[collections[0]]}
      carouselStyle={
        (data.primary
          .product_recommendation_section_style as unknown as CarouselStyle) ||
        "original"
      }
      prismicStyle={data.primary}
    />
  )
}
